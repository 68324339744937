import { useState, useEffect } from "react"
import moment from 'moment'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from "../functions/generalFunctions"

const Modal = (props) => {

    const { teto_id } = props

    const anoAtual = moment().year()

    const [ano, setAno] = useState('')
    const [listaAnos, setListaAnos] = useState()
    const [valor, setValor] = useState('')


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }

    useEffect(() => {

        setAno('')
        setValor('')

        if (teto_id) {


            carrega()


        }

    }, [teto_id])


    useEffect(() => {

        carregaAnos()

    }, [])

    const carrega = () => {


        api.get(`teto/${teto_id}`).then((result) => {

            var data = result.data[0]

            setAno(data.ano)
            setValor(data.valor ? fmtMoney(data.valor, 2) : null)
            

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const salvar = () => {


        if (valor == '' || valor == null) {


            window.toastr.error('<b>Valor do teto</b> não pode ficar em branco!')
            return false

        }

        if (ano == '' || ano == null) {

            window.toastr.error('<b>Ano</b> não pode ficar em branco!')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {

                                
                        var dataPost = {

                            ano: ano,
                            valor: valor ? valor.replaceAll('.', '').replaceAll(',', '.') : null,

                        }

                        console.log(dataPost)

                        if (!teto_id) {

                            //console.log('post')
                            //return false

                            api.post('teto', dataPost).then((result) => {

                                //console.log(result.data)

                                window.toastr.success('Registro salvo com sucesso!')
                                window.$('#modalTeto').modal('hide')
                                props.consulta()

                            }).catch((err) => {
                    
                                console.log(err.response)
                    
                            })


                        } else {

                            //console.log('put')
                            //return false
                            
                            api.put(`teto/${teto_id}`, dataPost).then((result) => {

                                //console.log(result.data)
                                window.toastr.success('Registro salvo com sucesso!')
                                window.$('#modalTeto').modal('hide')
                                props.consulta()



                            }).catch((err) => {
                    
                    
                    
                            })

                        }


                    },
                    Cancelar: function () {
                        //$.alert('Canceled!');
                    }
    
                },
    
            });


    }

    const apagar = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {


                        api.delete(`teto/${teto_id}`).then(() => {

                            //console.log(result.data)

                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('#modalTeto').modal('hide')
                            props.consulta()

                        }).catch((err) => {
                
                            console.log(err.response)
                
                        })

                    },
                    Cancelar: function () {
                        //$.alert('Canceled!');
                    }
    
                },
    
            });



    }

    return (

        <div>


                <div class="modal fade modal-Tetos"
                    id="modalTeto"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                    <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Teto</h4>
                        </div>
                        <div class="modal-body">

                        <div className="row">
                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Ano</label>
                                        <select className="form-control" value={ano} onChange={event => setAno(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            {listaAnos}
                                        </select>
                                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Valor Teto</label>
                                        <CurrencyInput type="text" value={valor} onChange={event => setValor(event.target.value)} className='form-control' placeholder='0,00' />
                                                        
                                    </div>
                                </div>
                                
                           </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger" onClick={() => apagar()} style={{ display: teto_id ? 'table-row' : 'none'}}>Apagar</button>
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salvar()}>Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )

}

export default Modal