
import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import moment from 'moment'


const PlanejConsumoDetalhe = (props) => {

    const perfil = sessionStorage.getItem('perfil')

    const planejamento_id = props.planejamento_id


    const [siafisicoPregao, setSiafisicoPregao] = useState('')

    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')

    const [descricao, setDescricao] = useState('')
    const [ano, setAno] = useState('')
    const [estoque, setEstoque] = useState('')
    const [grupo, setGrupo] = useState('')
    const [item_id, setItem_id] = useState('')
    const [metaCompra, setMetaCompra] = useState('')
    const [metaConsumo, setMetaConsumo] = useState('')
    const [valorReferencial, setValorReferencial] = useState('')

    const [margemSeguranca, setMargemSeguranca] = useState('')
    const [criticidade, setCriticidade] = useState('')
    const [aumentoProducao, setAumentoProducao] = useState('')
    const [totalEntregar, setTotalEntregar] = useState('')
    const [planejado, setPlanejado] = useState('')


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    



    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    const limpaDados = () => {

        setSiafisicoPregao('')
        setDescricao('')
        setAno('')
        setEstoque('')
        setGrupo('')
        setItem_id('')
        setMetaCompra('')
        setMetaConsumo('')
        setValorReferencial('')
        setCodigoFilhoMV('')
        setCodigoMestreMV('')
        setMargemSeguranca('')
        setCriticidade('')
        setAumentoProducao('')
        setTotalEntregar('')
        setPlanejado('')

    }


    const consulta = () => {


        if (planejamento_id) {


            api.get(`planejamento/${planejamento_id}`).then((result) => {

                var data = result.data[0]

                //console.log(data)

                setSiafisicoPregao(data.siafisicoPregao)
                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV)
                setDescricao(data.descricao)
                setAno(data.ano)
                setEstoque(data.estoque)
                setGrupo(data.grupo)
                setItem_id(data.item_id)
                setMetaCompra(data.metaCompra)
                setMetaConsumo(data.metaConsumo)
                setValorReferencial(fmtMoney(data.valorReferencial, 2).replaceAll('R$', ''))

                setMargemSeguranca(data.margemSeguranca)
                setCriticidade(data.criticidade)
                setAumentoProducao(data.aumentoProducao)
                setTotalEntregar(data.totalEntregar)
                setPlanejado(data.planejado)

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }


    const salvar = () => {

        if (item_id == '' || item_id == null) {

            window.toastr.error('<b>Cód. Siafisico</b> não pode ficar em branco!')
            return false;

        }

        if (metaCompra == '') {

            window.toastr.error('<b>Meta Compra</b> não pode ficar em branco!')
            return false;

        }

        if (metaConsumo == '') {

            window.toastr.error('<b>Meta Consumo</b> não pode ficar em branco!')
            return false;

        }

        if (valorReferencial == '') {

            window.toastr.error('<b>Valor referencial</b> não pode ficar em branco!')
            return false;

        }

        var dataPost = {

            ano: ano,
            item_id: item_id,
            estoque: estoque,
            metaCompra: metaCompra,
            metaConsumo: metaConsumo,
            valorReferencial: valorReferencial.replaceAll('.', '').replaceAll(',','.'),
            margemSeguranca: margemSeguranca && margemSeguranca != '' ? margemSeguranca : null,
            criticidade: criticidade && criticidade != '' ? criticidade : null,
            aumentoProducao: aumentoProducao && aumentoProducao != '' ? aumentoProducao : null,
            totalEntregar: totalEntregar && totalEntregar != '' ? parseInt(totalEntregar) : null,
            planejado: planejado && planejado != '' ? planejado : null,

        }

        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {

                    if (planejamento_id) {


                        api.put(`planejamento/${planejamento_id}`, dataPost).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro salvo com sucesso!')
                                //window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })


                    } else {


                        api.post('planejamento', dataPost).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro salvo com sucesso!')
                                //window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })


                    }
                    
                        
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }

    const apagar = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o empenho?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`planejamento/${planejamento_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

        
    }


    const infoItem = () => {

        if (siafisicoPregao != '' || codigoMestreMV != '' || codigoFilhoMV != '') {


            var dataPost = {

                siafisicoPregao: siafisicoPregao,
                codigoMestreMV: codigoMestreMV,
                codigoFilhoMV: codigoFilhoMV,

            }

            api.post('infoItem', dataPost).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setSiafisicoPregao(result.data[0].siafisicoPregao)
                    setCodigoMestreMV(result.data[0].codigoMestreMV)
                    setCodigoFilhoMV(result.data[0].codigoFilhoMV)
                    setItem_id(result.data[0].item_id)

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')
                    setCodigoFilhoMV('')
                    setCodigoMestreMV('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')
                setCodigoFilhoMV('')
                setCodigoMestreMV('')

            })

        }
        
    }



    useEffect(() => {

        limpaDados()
        consulta()
        carregaAnos()
       

    }, [planejamento_id])


    return (
        <div>
            

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>

                    </fieldset>
                </div>
            
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label">Cód. Siafisico</label>
                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setSiafisicoPregao(event.target.value);setCodigoMestreMV('');setCodigoFilhoMV('');}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            
                            }
                        }} 
                        onBlur={() => infoItem()}
                        />
                    </fieldset>
                </div>

                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label">Cód. Mestre MV</label>
                        <input type="text" className="form-control" value={codigoMestreMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoMestreMV(event.target.value);setSiafisicoPregao('');setCodigoFilhoMV('');}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        onBlur={() => infoItem()}
                        />
                    </fieldset>
                </div>

                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label">Cód. Filho MV</label>
                        <input type="text" className="form-control" value={codigoFilhoMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoFilhoMV(event.target.value);setSiafisicoPregao('');setCodigoMestreMV('');}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        onBlur={() => infoItem()}
                        />
                    </fieldset>
                </div>
                
                <div className="col-md-12">
                    <fieldset className="form-group">
                        <label className="form-label">Descrição</label>
                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                    </fieldset>
                </div>


                <div className="col-md-6">
                    <fieldset className="form-group">
                        <label className="form-label">Valor Referencial R$</label>
                        <CurrencyInput type="text" className="form-control" value={valorReferencial} style={{ backgroundColor: '#e8d4c5'}} onChange={event => setValorReferencial(event.target.value)} placeholder="0,00" />
                    </fieldset>
                </div>

                <div className="col-md-6">
                    <fieldset className="form-group">
                        <label className="form-label">Criticidade</label>
                        
                        <select className='form-control' value={criticidade} onChange={event => setCriticidade(event.target.value)}>
                            <option value="">[Selecione]</option>
                            <option value="2">2 - Baixa</option>
                            <option value="3">3 - Baixa</option>
                            <option value="4">4 - Média</option>
                            <option value="5">5 - Alta</option>
                            <option value="6">6 - Alta</option>

                        </select>
                    </fieldset>
                </div>


                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Planejado</label>
                        <input type="text" className="form-control" value={planejado} onChange={event => setPlanejado(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Margem Segurança</label>
                        <input type="text" className="form-control" value={margemSeguranca} onChange={event => setMargemSeguranca(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Aumento Produção</label>
                        <input type="text" className="form-control" value={aumentoProducao} onChange={event => setAumentoProducao(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Meta Consumo</label>
                        <input type="text" className="form-control" value={metaConsumo} onChange={event => setMetaConsumo(event.target.value)}
                        style={{ backgroundColor: '#b5f5f1'}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Estoque ano anterior</label>
                        <input type="text" className="form-control" value={estoque} onChange={event => setEstoque(event.target.value)}
                        style={{ backgroundColor: '#f0a6a1'}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Empenhados a entregar</label>
                        <input type="text" className="form-control" value={totalEntregar} onChange={event => setTotalEntregar(event.target.value)}
                        style={{ backgroundColor: '#f0a6a1'}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>


                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Plano de Compras</label>
                        <input type="text" className="form-control" value={metaCompra} onChange={event => setMetaCompra(event.target.value)}
                        style={{ backgroundColor: '#b5f5f1'}}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>
               


                <div className="col-md-12">
                    <br/>
                    <div align="right">
                        

                        
                        <button type="button" className="btn btn-inline btn-danger" style={{ display: planejamento_id ? 'table-row' : 'none' }} onClick={() => apagar()} disabled={ true }>Apagar</button>&nbsp;
                        <button type="button" className="btn btn-inline btn-success" onClick={() => salvar()} disabled={ true }>Salvar</button>

                    </div>
                </div>
                      
                

            </div>


        </div>
    )

}

export default PlanejConsumoDetalhe