
import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, cor, rotulo } from '../functions/generalFunctions';
import moment from 'moment'
import ModalPlanejConsumo from '../modal/ModalPlanejConsumo'
import ModalGeraExcel from '../modal/ModalGeraExcel'
import ModalAlteraMeta from '../modal/ModalAlteraMeta'
import Badge from 'react-bootstrap/Badge'



const Planejamento = () => {

    const perfil = sessionStorage.getItem('perfil')


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())

    const [planejamento_id, setPlanejamento_id] = useState()
    
    const [resultado, setResultado] = useState([])
    const [resultadoAlt, setResultadoAlt] = useState([])
    const [resultadoNP, setResultadoNP] = useState([])
    
    const [item_id, setItem_id] = useState('')

    const [prioridade, setPrioridade] = useState(false)
    const [padronizado, setPadronizado] = useState(false)


    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    const columns = useMemo(
        () => [
          {
            accessorKey: 'planejamentoInicial', //access nested data with dot notation
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'criticidade', //access nested data with dot notation
            header: 'Criticidade',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            Cell: ({ renderedCellValue, row }) => (
            
              //<span class={`label label-${ renderedCellValue == 'C' ? 'success' : renderedCellValue == 'I' ? 'warning' : renderedCellValue == 'E' ? 'danger' : renderedCellValue == 'O' ? 'info' : 'primary' }`}>{rotulo(renderedCellValue)}</span>
              <Badge style={{  backgroundColor: cor(renderedCellValue) }}>{renderedCellValue} - {rotulo(renderedCellValue)}</Badge>
          
            ),
          },
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoMestreMV', //access nested data with dot notation
            header: 'Cód. Mestre MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoFilhoMV', //access nested data with dot notation
            header: 'Cód. Filho MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          
          {
            accessorKey: 'grupo',
            header: 'Grupo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'ano', //normal accessorKey
            header: 'Ano',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'valorReferencial',
            header: 'Valor Referencial',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'metaConsumo',
            header: 'Meta Consumo',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'estoque',
            header: 'Estoque',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          {
            accessorKey: 'metaCompra',
            header: 'Meta Compra',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },         
          
        ],
        [],
      );



      const columnsAlt = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          
          {
            accessorKey: 'metaAnterior', //access nested data with dot notation
            header: 'Meta anterior',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          {
            accessorKey: 'metaCorrigida',
            header: 'Meta corrigida',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'percentual', //normal accessorKey
            header: '% variação',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
         
        ],
        [],
      );




      const columnsNP = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'codigoMestreMV', //access nested data with dot notation
            header: 'Cód. MV Mestre',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'codigoFilhoMV', //access nested data with dot notation
            header: 'Cód. MV Filho',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          
          {
            accessorKey: 'totalQtdeEmProcesso', //access nested data with dot notation
            header: 'Qtde em processo',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          {
            accessorKey: 'totalQtdeEmpenhado',
            header: 'Qtde empenhado',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
         
        ],
        [],
      );



    const carrega = () => {

      var dataPost = {

        prioridade: prioridade,
        padronizado: padronizado,
        ano: ano,

      }

        api.post('planejamentoLista', dataPost).then((result) => {

            var data = result.data

            //console.log(data)

            setResultado(data.map((rs) => {

                return {

                    planejamento_id: rs.planejamento_id,
                    siafisicoPregao: rs.siafisicoPregao,
                    descricao: rs.descricao,
                    item_id: rs.item_id,
                    grupo: rs.grupo,
                    ano: rs.ano,
                    codigoFilhoMV: rs.codigoFilhoMV,
                    codigoMestreMV: rs.codigoMestreMV,
                    criticidade: rs.criticidade,
                    valorReferencial: rs.valorReferencial == null ? 0 : fmtMoney(rs.valorReferencial, 2).replaceAll('R$', ''),
                    metaConsumo: rs.metaConsumo == null ? 0 : fmtMoney(rs.metaConsumo, '0').replaceAll('R$', ''),
                    estoque: rs.estoque == null ? 0 : fmtMoney(rs.estoque, '0').replaceAll('R$', ''),
                    metaCompra: rs.metaCompra == null ? 0 : fmtMoney(rs.metaCompra, '0').replaceAll('R$', ''),
                    planejamentoInicial: rs.planejamentoInicial == ano ? 'Sim' : 'Não',

                    prioridade: prioridade,
                    padronizado: padronizado,
                    

                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaAlteracoes = () => {

        var dataPost = {

            ano: ano,
            prioridade: prioridade,
            padronizado: padronizado,

        }

        api.post('planejamentoCorrigidoLista', dataPost).then((result) => {

            var data = result.data

            

            setResultadoAlt(data.map((rs) => {


                return {


                    descricao: rs.descricao,
                    ano: rs.ano,
                    codigoMestreMV: rs.codigoMestreMV,
                    codigoFIhoMV: rs.codigoFIhoMV,
                    siafisicoPregao: rs.siafisicoPregao,
                    item_id: rs.item_id,
                    nome: rs.nome,
                    percentual: rs.percentual == null ? 0 : fmtMoney(rs.percentual, 0).replaceAll('R$', ''),
                    metaAnterior: rs.metaAnterior == null ? 0 : fmtMoney(rs.metaAnterior, 0).replaceAll('R$', ''),
                    metaCorrigida: rs.metaCorrigida == null ? 0 : fmtMoney(rs.metaCorrigida, 0).replaceAll('R$', ''),

                    prioridade: prioridade,
                    padronizado: padronizado,

                }

            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaItensNaoPlanejados = () => {

        var dataPost = {

          ano: ano,
          prioridade: prioridade,
          padronizado: padronizado,

        }

        api.post('itensNaoPlanejados', dataPost).then((result) => {

            //console.log(result.data)

            setResultadoNP(result.data.map((rs) => {

                return {

                    siafisicoPregao: rs.siafisicoPregao,
                    codigoMestreMV: rs.codigoMestreMV,
                    codigoFilhoMV: rs.codigoFilhoMV,
                    descricao: rs.descricao,
                    totalEmProcesso: rs.totalEmProcesso,
                    totalEmpenhado: rs.totalEmpenhado,
                    //totalEmProcesso: rs.totalEmProcesso,
                    //totalEmpenhado: rs.totalEmpenhado,
                    totalQtdeEmProcesso: rs.totalQtdeEmProcesso.toLocaleString('pt-BR'),
                    totalQtdeEmpenhado: rs.totalQtdeEmpenhado.toLocaleString('pt-BR'),

                    prioridade: prioridade,
                    padronizado: padronizado,

                }

            }
            ))

        }).catch((err) => {

            console.log(err)

        })


    }




    useEffect(() => {

        carregaAnos()
        carregaAlteracoes()
        carregaItensNaoPlanejados()
        carrega()

    }, [ano, padronizado, prioridade])


    return (

        <div>

                <div id="contentwrapper">
                    <div class="main_content">
                        <div id="jCrumbs" class="breadCrumb module">
                            <ul>
                                <li>
                                    <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                                </li>
                                <li>
                                    Planejamento
                                </li>
                                
                            </ul>
                        </div>	
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
            


                            <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id()}} disabled={ perfil == 'Administrador' ? false : true }>+ Novo</button>&nbsp;
                            <button type="button" class="btn btn-inline btn-success" onClick={() => window.$('.modal-GeraExcel').modal('show')}>Exportar Excel</button>&nbsp;
                            <button type="button" class="btn btn-inline btn-info" onClick={() => {window.$('.modal-AlteraMeta').modal('show');setItem_id('')}}>Lançar alteração de meta</button>&nbsp;
                                    
                            <br/><br/>

                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>

                                    </fieldset>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                      <fieldset className="form-group">
                                          <label className="form-label" for="exampleError">Itens críticos</label>
                                          <select type="text" className="form-control" value={prioridade} onChange={event => setPrioridade(event.target.value)}>
                                              <option value={false}>Todos os itens</option>
                                              <option value={true}>Somente itens críticos</option>
                                          </select>
                                          
                                      </fieldset>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                      <fieldset className="form-group">
                                          <label className="form-label" for="exampleError">Padronizado</label>
                                          <select type="text" className="form-control" value={padronizado} onChange={event => setPadronizado(event.target.value)}>
                                              <option value={false}>Todos os itens</option>
                                              <option value={true}>Somente itens padronizados</option>
                                          </select>
                                          
                                      </fieldset>
                                  </div>
                            

                            </div>

                            <div class="row">
                                <div class="col-sm-6 col-md-12">
                                    
                                    <div class="tabbable tabbable-bordered">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a href="#tab1" data-toggle="tab">Inicial</a></li>
                                            <li><a href="#tab2" data-toggle="tab">Alterações</a></li>
                                            <li><a href="#tab3" data-toggle="tab">Itens não planejados</a></li>
                                            
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="tab1">
                                                
                                                    
                                                <MaterialReactTable 
                                    
                                                    columns={columns} 
                                                    data={resultado} 
                                                    localization={MRT_Localization_PT_BR}
                                                    //rowsPerPage={20}
                                                    //options={options}
                                                    initialState={{ density: 'compact' }}
                                                    muiTableHeadCellProps={{
                                                        //easier way to create media queries, no useMediaQuery hook needed.
                                                        sx: {
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '9px',
                                                            md: '10px',
                                                            lg: '11px',
                                                            xl: '12px',
                                                        },
                                                        },
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontSize: {
                                                            xs: '8px',
                                                            sm: '9px',
                                                            md: '10px',
                                                            lg: '11px',
                                                            xl: '12px',
                                                            },
                                                        },
                                                    }}

                                                    muiTableBodyRowProps={({ row }) => ({
                                                    onClick: (event) => {
                                                        //console.info(event, row.original.usuario_id);
                                                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                                        window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id(row.original.planejamento_id);setItem_id(row.original.item_id)

                                                    },
                                                    sx: {
                                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                    },
                                                    })}
                                                    
                                                />



                                            </div>
                                            <div class="tab-pane" id="tab2">
                                                
                                                                
                                                    <MaterialReactTable 
                                                
                                                        columns={columnsAlt} 
                                                        data={resultadoAlt} 
                                                        localization={MRT_Localization_PT_BR}
                                                        //rowsPerPage={20}
                                                        //options={options}
                                                        initialState={{ density: 'compact' }}
                                                        muiTableHeadCellProps={{
                                                            //easier way to create media queries, no useMediaQuery hook needed.
                                                            sx: {
                                                            fontSize: {
                                                                xs: '8px',
                                                                sm: '9px',
                                                                md: '10px',
                                                                lg: '11px',
                                                                xl: '12px',
                                                            },
                                                            },
                                                        }}
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                fontSize: {
                                                                xs: '8px',
                                                                sm: '9px',
                                                                md: '10px',
                                                                lg: '11px',
                                                                xl: '12px',
                                                                },
                                                            },
                                                        }}

                                                        muiTableBodyRowProps={({ row }) => ({
                                                        onClick: (event) => {
                                                            //console.info(event, row.original.usuario_id);
                                                            //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                                            //window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id(row.original.planejamento_id);setItem_id(row.original.item_id)

                                                        },
                                                        sx: {
                                                            //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                        },
                                                        })}
                                                        
                                                    />


                                            </div>
                                            <div class="tab-pane" id="tab3">

                                                <MaterialReactTable 
                                                    
                                                    columns={columnsNP} 
                                                    data={resultadoNP} 
                                                    localization={MRT_Localization_PT_BR}
                                                    //rowsPerPage={20}
                                                    //options={options}
                                                    initialState={{ density: 'compact' }}
                                                    muiTableHeadCellProps={{
                                                        //easier way to create media queries, no useMediaQuery hook needed.
                                                        sx: {
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '9px',
                                                            md: '10px',
                                                            lg: '11px',
                                                            xl: '12px',
                                                        },
                                                        },
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontSize: {
                                                            xs: '8px',
                                                            sm: '9px',
                                                            md: '10px',
                                                            lg: '11px',
                                                            xl: '12px',
                                                            },
                                                        },
                                                    }}

                                                    muiTableBodyRowProps={({ row }) => ({
                                                    onClick: (event) => {
                                                        //console.info(event, row.original.usuario_id);
                                                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                                        //window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id(row.original.planejamento_id);setItem_id(row.original.item_id)

                                                    },
                                                    sx: {
                                                        //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                    },
                                                    })}
                                                    
                                                />


                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                            <ModalPlanejConsumo planejamento_id={planejamento_id} carregaPlanej={carrega} item_id={item_id} />
                            <ModalGeraExcel />
                            <ModalAlteraMeta item_id={item_id} carregaAlteracoes={carregaAlteracoes} carrega={carrega} />




                            </div>
                        </div>                
                    </div>
                </div>

            

        </div>
    )

}

export default Planejamento

