import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router';
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions';
import LoaderIcon from "react-loader-icon";
import moment from 'moment'


const SugestaoCompra = () => {

    const navigate = useNavigate()

    const ano = moment().format('YYYY')
    //const ano = 2024

    const [carregando, setCarregando] = useState('none')
    const [resultado, setResultado] = useState([])


    async function exportaExcel() {



        await api.get(`exportaSugestaoCompra`).then((result) => {
  
          console.log(result.data)
  
          if (result.data.status == 'ok') {
  
            window.toastr.info('Aguarde, gerando XLSX...')
  
              sleep(2000).then(() => {
  
                  //console.log(result.data[0].fileNameCompleto, '!!')
                  //window.location.href = result.data.arquivo
                  //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                  window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                  //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
  
              })
              
  
          }
  
      }).catch((err) => {
  
          console.log(err.response)
  
      })
  
  
    }




    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'codigoMestreMV', //access nested data with dot notation
            header: 'Cód. Mestre MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'codigoFilhoMV', //access nested data with dot notation
            header: 'Cód. Filho MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
        
          {
            accessorKey: 'descricaoFaixa', //normal accessorKey
            header: 'Dias',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'estoqueAtual', //normal accessorKey
            header: 'Estoque atual',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'qtdeEmpenhadoEntregar',
            header: 'Quantidade empenhado entregar',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'consumoMedioDiario',
            header: 'Consumo Médio Diário',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          
          
        ],
        [],
      );

    
    const atualizaSugestaoCompra = () => {


        setCarregando('block')

        api.get(`montaSugestaoCompra/${ano}`).then((result) => {

            if (result.data.status == 'ok') {

                carrega()

            }

        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })


    }

    const carrega = () => {

        setCarregando('block')

        api.get('sugestaoCompra').then((result) => {

            setResultado(result.data)
            setCarregando('none')

        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })


    }

    
    useEffect(() => {

        carrega()

    }, [])

    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Sugestão de Compras
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">

                        
                        <div class="col-sm-12 col-md-12">


                            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>&nbsp;
                            <button type="button" class="btn btn-inline btn-info" onClick={() => atualizaSugestaoCompra()} >Atualizar Sugestão de Compra</button>
                                    
                            <br/><br/>
                     

                            <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                
                            <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>

                                <MaterialReactTable 
                                    
                                    columns={columns} 
                                    data={resultado} 
                                    localization={MRT_Localization_PT_BR}
                                    //rowsPerPage={20}
                                    //options={options}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        //easier way to create media queries, no useMediaQuery hook needed.
                                        sx: {
                                        fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                        },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                            },
                                        },
                                    }}

                                    muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        //console.info(event, row.original.usuario_id);
                                        navigate(`/Item/${row.original.item_id}`)
                                        //window.$('.modal-Processo').modal('show');setProcesso_id(row.original.processo_id)

                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                    })}
                                    
                                />

                                </div>

                            </div>






                        </div>
                    </div>                
                </div>
            

        </div>

    )

}

export default SugestaoCompra