import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Modal = (props) => {

    const dadosGrafico = props.dadosGrafico

    const navigate = useNavigate()

    const [nomeGrafico, setNomeGrafico] = useState('')
    const [resultado, setResultado] = useState([])




    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao',
            header: 'Siafisico Pregão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
         
          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          /*
          {
            accessorKey: 'unidFornecimento',
            header: 'Unidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
         */
          
          {
            accessorKey: 'percentual',
            header: '%',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
        
        ],
        [],
      );



    useEffect(() => {

        console.log(props)

        if (dadosGrafico) {


            setNomeGrafico(dadosGrafico.name)

            var dataPost = {

                parametro: props.dadosGrafico.name

            }

            //console.log(dataPost)

            api.post('detalheGraficoConsumo', dataPost).then((result) => {

                //console.log(result.data)
                setResultado(result.data)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }, [dadosGrafico])


    return (

        <div>

                <div className='modal fade modal-Chart'
                    id="modalDetalhePieChart"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">{nomeGrafico}</h4>
                        </div>
                        <div class="modal-body">

                            
                            <MaterialReactTable 
                                    
                                    columns={columns} 
                                    data={resultado} 
                                    localization={MRT_Localization_PT_BR}
                                    //rowsPerPage={20}
                                    //options={options}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        //easier way to create media queries, no useMediaQuery hook needed.
                                        sx: {
                                        fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                        },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                            },
                                        },
                                    }}

                                    muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        //console.info(event, row.original.usuario_id);
                                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                        //window.$('.modal-CatalogoEdit').modal('show');setItem_id(row.original.item_id)
                                        window.$('#modalDetalhePieChart').modal('hide');
                                        navigate(`/Item/${row.original.item_id}`);
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                    })}
                                    
                                />




                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default Modal